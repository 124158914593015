<template>
  <footer class="p-6 w-full bg-gray-100">
    <div class="container mx-auto">
      <p class="text-sm text-gray-600">
        Achiever v2.9.0 &copy;2024
        <a href="https://evanchiu.com">Evan Chiu</a> / Valecia Roxtar.2483
      </p>
      <p class="text-xs text-gray-600">
        &copy; 2024 ArenaNet, LLC. All rights reserved. NCSOFT, the interlocking
        NC logo, ArenaNet, Guild Wars, Guild Wars Factions, Guild Wars
        Nightfall, Guild Wars: Eye of the North, Guild Wars 2, and all
        associated logos and designs are trademarks or registered trademarks of
        NCSOFT Corporation. All other trademarks are the property of their
        respective owners.
      </p>
    </div>
  </footer>
</template>
